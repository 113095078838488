.hm-btn {
  background-color: #00a4e1;
  border: none;
  outline: none;
  width: 120px;
  border-radius: 28px 28px 28px 0px;
  font-size: 15px;
  font-family: "Poppins";
  padding: 10px;
  margin: 0px 10px;
}
.hm-btn2 {
  background-color: #00a4e1;
  border: none;
  outline: none;
  width: 120px;
  border-radius: 28px 28px 0px 28px;
  font-size: 15px;
  font-family: "Poppins";
  padding: 10px;
}
.hm-title1 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
}
.hm-paras {
  font-family: "Poppins";
  text-align: justify;
}
.hm-btn3 {
  background-color: #00a4e1;
  border: none;
  outline: none;
  border-radius: 28px 28px 28px 0px;
  font-size: 15px;
  font-family: "Poppins";
  padding: 10px;
}
