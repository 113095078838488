.lay-head {
  background: #1b396a;
  height: 100%;
}
.brandLogo {
  height: 35px;
}
.nav-name {
  font-size: 15px;
  font-family: "Poppins";
  color: #00a4e1;
}
.custom-btn-color {
  background-color: #00a4e1;
  border: none;
  outline: none;
  width: 120px;
  border-radius: 20px 20px 0px 20px;
  font-size: 15px;
  font-family: "Poppins";
}
.custom-btn-color:hover {
  background-color: #00a4e1;
}
.navbars {
  gap: 10px;
}
/* css for modal buttons */
.submit-modal-btn{
  width: 150px;
  height: 50px;
  border: none !important;
  border-radius: 20px 20px 0px 20px;
  padding:0px 20px;
  background-color: #00a4e1;
  font-family: "Poppins";
  color: white !important;
}
.submit-modal-btn:hover{
  width: 150px;
  height: 50px;
  border: none !important;
  border-radius: 20px 20px 0px 20px;
  padding:0px 20px;
  color: #00a4e1 !important;
  border: 0.5px solid #00a4e1 !important;
  font-family: "Poppins";
  background-color: #e9f2f5 !important;
}
.cancel-modal-btn{
  width: 150px;
  height: 50px;
  border: none !important;
  border-radius: 20px 20px 20px 0px;
  padding:0px 20px;
  margin: 5px;
  font-weight: 600;
}
/* customization css */
.ant-modal-title{
color: #1b396a !important;
}
.ant-modal-footer{
  display: none !important;
}
/* modal inputs */
.modal-input-register{
  border: 1px solid #0e0e0e;
    border-radius: 20px;
    padding: 6px;
    outline: none;
    min-width: 300px;
    margin-bottom: 4px;
}
.error-message{
  color: red !important;
}
.container-register label{
  font-family: "Poppins";
  color: rgb(7, 7, 7) !important;
  font-weight: 700;
}
.container-register-div-input{
  display: flex;
  justify-content: space-between;
  margin: 0px 25px;
  align-items: center !important;
}
